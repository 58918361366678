import { format, parse } from 'date-fns'
import { ru } from 'date-fns/locale'
import type { DataKeys, Meta } from 'zhead'

import AuthScope from '~/enums/auth-scope'
import type ImageType from '~/enums/image-type'
import Page from '~/enums/page'
import type { BrandData } from '~/types/brands'
import type { ProductData, ProductLabel, ProductShortData } from '~/types/products'

function adjustForTimeZone (date: Date): Date {
  const offsetInMs = date.getTimezoneOffset() * 60000
  date.setTime(date.getTime() + offsetInMs)

  return date
}

function getDateTimeInMsk (dateTimeString: string): Date {
  const [datePart, timePart] = dateTimeString.split('T')
  const [year, month, day] = datePart.split('-').map(Number)
  const [hour, minute, second] = timePart.split(/[:+]/).map(Number)
  const utcTimestamp = Date.UTC(year, month - 1, day, hour, minute, second)
  const moscowTimestamp = utcTimestamp + 10800000 // 10800000 ms is 3 hours.

  return adjustForTimeZone(new Date(moscowTimestamp))
}

export default {
  convertKopecksToRubles: (kopecks: number | null): number | null => kopecks === null ? null : kopecks / 100,
  convertRublesToKopecks: (rubles: string | number | null): number | null => {
    if (rubles === null) {
      return null
    }

    if (typeof rubles === 'string') {
      if (rubles.trim() === '') {
        return null
      }

      rubles = Number(rubles.replace(',', '.'))
    }

    return Math.round(100 * rubles)
  },
  formatCashback: (cashback: number | null): string => typeof cashback === 'number'
    ? String(Math.round(10000 * cashback) / 100).replace('.', ',')
    : '',
  formatDate: (dateString: string): string => format(parse(dateString, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy'),
  formatDateToString: (dateTimeString: string, formatString = 'd MMMM'): string => format(
    getDateTimeInMsk(dateTimeString),
    formatString,
    { locale: ru }
  ),
  formatDateTime: (dateTimeString: string, formatString = 'd MMM yyyy, HH:mm'): string => format(
    getDateTimeInMsk(dateTimeString),
    formatString,
    { locale: ru }
  ),
  formatBadgeText (count: number | null): string | null {
    if (count === null) {
      return null
    }

    switch (true) {
      case count < 1e3:
        return String(count)
      case count >= 1e3 && count < 1e6:
        return `${Math.trunc(count / 1e3)}К`
      case count >= 1e6:
        return `${Math.trunc(count / 1e6)}М`
      default:
        return String(count)
    }
  },
  formatFileSize: (bytes: number, precision = 2): string => {
    if (bytes === 0) {
      return '0 б'
    }

    const base = 1024
    const postfixes = ['б', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'ЭБ', 'ЗБ', 'ЙБ']
    const index = Math.floor(Math.log(bytes) / Math.log(base))

    return `${parseFloat((bytes / Math.pow(base, index)).toFixed(precision))} ${postfixes[index]}`
      .replace('.', ',')
  },
  formatFloatingPointNumber: (number: number): string => String(number).replace('.', ','),
  formatPrice (price: number | null, isInKopecks = false): string {
    if (isInKopecks) {
      price = this.convertKopecksToRubles(price)
    }

    if (typeof price !== 'number') {
      return ''
    }

    const parts = price.toString().split('.')
    const wholePartAsNumber = Number(parts[0])
    const wholePart = wholePartAsNumber >= 1000 && wholePartAsNumber <= 9999
      ? parts[0]
      : parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\xA0')

    return parts.length === 1 ? wholePart : `${wholePart},${parts[1]}`
  },
  getArticlePath: (articleData: any): string => `${Page.Articles}${articleData.attributes.slug}-${articleData.id}/`,
  getBrandPath: (brandData: BrandData): string => `${Page.Brands}${brandData.attributes.slug}-${brandData.id}/`,
  getCategoryPath: (categoryData: any): string => `${Page.Categories}${categoryData.attributes.slug}-${categoryData.id}/`,
  getCollectionPath: (collectionData: any): string =>
    `${Page.Collections}${collectionData.attributes.slug}-${collectionData.id}/`,
  getDeclension: (number: number, cases: string[]): string =>
    cases[number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2, 2, 2, 2, 2][number % 10]],
  getFullName (data: any): string | null {
    const { firstName, lastName, patronymic } = data.attributes

    if (firstName && lastName && patronymic) {
      return `${lastName} ${firstName} ${patronymic}`
    }

    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }

    return firstName
  },
  getIdFromSlugAndId: (slugAndId: string): string | null => slugAndId.split('-').pop() || null,
  getImageUrl (imagePaths: any, imageTypes: ImageType[]): string | null {
    for (const x of imageTypes) {
      if (imagePaths[x]) {
        return imagePaths[x]
      }
    }

    const keys = Object.keys(imagePaths)

    return keys.length ? keys[0] : null
  },
  getImageUrls (imagesData: any[]): object | null {
    if (!imagesData.length) {
      return null
    }

    const imagesUrls: any = {}

    for (const x of imagesData) {
      imagesUrls[x.meta.type] = x.meta.url
    }

    return imagesUrls
  },
  getListPath: (listData: any): string => `${Page.Lists}${listData.id}/`,
  getPageTitle: (title: string, authScope: AuthScope): string =>
    `${title} — ${AuthScope.getUserSectionName(authScope)} — iTAB`,
  getParams: (params: { condition: any, name: string, value: any }[]): object => Object
    .fromEntries(
      params
        .filter((x: { condition: any, name: string, value: any }) => x.condition)
        .map((x: { condition: any, name: string, value: any }) => [x.name, x.value])
    ),
  getPreviewMetadata: (previewUrl: string): (Meta & DataKeys)[] => [
    { name: 'og:image', property: 'og:image', content: previewUrl },
    { name: 'vk:image', property: 'vk:image', content: previewUrl },
    { name: 'twitter:image', content: previewUrl }
  ],
  getProductDiscountedPrice (productData: ProductData): number | null {
    const { discountInKopecks, quantity } = productData.meta
    const productPrice = this.getProductPrice(productData)

    if (discountInKopecks) {
      return this.convertKopecksToRubles(
        Math.round((this.convertRublesToKopecks(productPrice) ?? 0) - discountInKopecks / quantity!)
      )
    }

    return productPrice
  },
  getProductImageUrl (productData: ProductData, imageTypes: ImageType[]): string | null {
    const imageUrls = this.getImageUrls(productData.relationships.images.data)

    return imageUrls ? this.getImageUrl(imageUrls, imageTypes) : null
  },
  getProductPath: (productData: ProductShortData): string =>
    `${Page.Products}${productData.attributes.slug}-${productData.id}/`,
  getProductPrice: (productData: ProductData): number | null => productData.attributes.price,
  getReviewAuthorName: (authorName: string | null, isAnonymous: boolean): string =>
    isAnonymous ? 'Анонимный покупатель' : (authorName || 'Имя не указано'),
  getSpecialOfferPath: (specialOfferData: any): string =>
    `${Page.SpecialOffers}${specialOfferData.attributes.slug}-${specialOfferData.id}/`,
  getTestPath: (testData: any): string => `${Page.Tests}${testData.attributes.slug}-${testData.id}/`,
  getTotalPages: (totalItems: number, pageSize: number): number | null => totalItems === 0
    ? null
    : Math.ceil(totalItems / pageSize),
  getUtmParameters (): object | null {
    const localStorageKey = 'utmParameters'
    const utmParametersAsJsonString = localStorage.getItem(localStorageKey)

    if (utmParametersAsJsonString === null) {
      return null
    }

    let utmParametersFromLocalStorage = null

    try {
      utmParametersFromLocalStorage = JSON.parse(utmParametersAsJsonString)
    } catch (e) {
      console.error(e)

      localStorage.removeItem(localStorageKey)
    }

    if (typeof utmParametersFromLocalStorage !== 'object' || utmParametersFromLocalStorage === null) {
      localStorage.removeItem(localStorageKey)

      return null
    }

    const { utmCampaign, utmContent, utmMedium, utmSource, utmTerm } = utmParametersFromLocalStorage
    const utmParameters = this.purifyUtmParameters({ utmCampaign, utmContent, utmMedium, utmSource, utmTerm })

    if (utmParameters === null) {
      localStorage.removeItem(localStorageKey)

      return null
    }

    return utmParameters
  },
  isProductImageBlackAndWhite (productData: ProductData): boolean {
    const isOutOfStock = productData.meta?.isOutOfStock

    if (
      isOutOfStock
      && productData.meta?.labels?.some(
        (x: ProductLabel): boolean => ['Скоро в продаже', 'Предзаказ'].includes(x?.title)
      )
    ) {
      return false
    }

    return isOutOfStock ?? false
  },
  purifyUtmParameters (utmParameters: any): object | null {
    Object.keys(utmParameters).forEach((x: string): void => {
      if (typeof utmParameters[x] !== 'string' || utmParameters[x] === '') {
        delete utmParameters[x]
      }
    })

    const requiredUtmParameters = ['utmSource']

    for (const x of requiredUtmParameters) {
      if (!Object.prototype.hasOwnProperty.call(utmParameters, x)) {
        return null
      }
    }

    return utmParameters
  },
  roundToTwo (value: number): number {
    return +(Math.round(Number(`${value}e+2`)) + 'e-2')
  },
  validateField (field: any): string | null {
    const { isRequired, labelText, maxLength, modelValue, placeholder, validate } = field.props

    if (!isRequired) {
      return null
    }

    const name = labelText || placeholder

    if (!modelValue || field.maskPrefix === modelValue) {
      field.isValid = false

      if (field.isChanged === false) {
        field.isChanged = true
      }

      return `Поле «${name}» не может быть пустым.`
    }

    if (typeof validate === 'function' && typeof maxLength === 'number' && !field.isValid) {
      // let maxLength
      //
      // for (const x of field.$vnode.elm.children) {
      //   if (x.localName.includes('input')) {
      //     maxLength = field._name.includes('PhoneNumber')
      //       ? field.numberCount()
      //       : x.dataset.mask.length
      //   }
      // }
      //
      // if (maxLength === undefined) {
      //   console.error(`Please set mask for ${name}`)
      // }

      field.isValid = false

      if (field.isChanged === false) {
        field.isChanged = true
      }

      return `В поле «${name}» ${this.getDeclension(maxLength, ['должна', 'должно', 'должно'])} быть `
        + `${maxLength} ${this.getDeclension(maxLength, ['цифра', 'цифры', 'цифр'])}.`
    }

    return null
  }
}

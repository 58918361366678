import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import type AuthScope from '~/enums/auth-scope'

export default class QualityCertificates extends HttpFactory {
  private readonly resource = 'v1/qualityCertificates'

  public createQualityCertificate (formData: FormData, authScope: AuthScope) {
    return this.$fetch(
      this.resource,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token, authScope) }
    )
  }

  public getQualityCertificate (qualityCertificateId: string, authScope: AuthScope) {
    return this.$fetch(
      `${this.resource}/${qualityCertificateId}`,
      { headers: getAuthHeaders(this.authStore.token, authScope) }
    )
  }

  public getQualityCertificates (params: object, authScope: AuthScope) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token, authScope), params })
  }

  public updateQualityCertificate (qualityCertificateId: string, formData: FormData, authScope: AuthScope) {
    return this.$fetch(
      `${this.resource}/${qualityCertificateId}?_method=PATCH`,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token, authScope) }
    )
  }
}

import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import type AuthScope from '~/enums/auth-scope'
import type { GetRecommendationDocumentData } from '~/types/recommendation-documents'

export default class RecommendationDocuments extends HttpFactory {
  private readonly resource = 'v1/recommendationDocuments'

  public getRecommendationDocuments (authScope: AuthScope): Promise<GetRecommendationDocumentData> {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token, authScope) })
  }
}

import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import AuthScope from '~/enums/auth-scope'
import type OrderShippingType from '~/enums/order-shipping-type'
import type { GetProductChangesResponse } from '~/types/products'

export default class Actions extends HttpFactory {
  private readonly resource = 'v1/actions'

  public approveProductChanges (data: object): Promise<void> {
    return this.$fetch(
      `${this.resource}/approveProductChanges`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, AuthScope.Admin) }
      }
    )
  }

  public cancelPayment (orderId: string) {
    return this.$fetch(
      `${this.resource}/cancelPayment`,
      {
        method: 'POST',
        body: JSON.stringify({ orderId }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public cancelProductChanges (data: object): Promise<void> {
    return this.$fetch(
      `${this.resource}/cancelProductChanges`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, AuthScope.Admin) }
      }
    )
  }

  public capturePayment (data: object) {
    return this.$fetch(
      `${this.resource}/capturePayment`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public createParcel (orderId: string) {
    return this.$fetch(
      `${this.resource}/createParcel`,
      {
        method: 'POST',
        body: JSON.stringify({ orderId }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public createRefund (data: object) {
    return this.$fetch(
      `${this.resource}/createRefund`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }

  public getAvailableShippingDates (fiasId: string) {
    return this.$fetch(
      `${this.resource}/getAvailableShippingDates`,
      { headers: getAuthHeaders(this.authStore.token), params: { fiasId } }
    )
  }

  public getMonthlyBonus () {
    return this.$fetch(
      `${this.resource}/getMonthlyBonus`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getProductChanges (productId: string): Promise<GetProductChangesResponse> {
    return this.$fetch(
      `${this.resource}/getProductChanges`,
      { headers: getAuthHeaders(this.authStore.token, AuthScope.Admin), params: { productId } }
    )
  }

  public getShippingCost (shippingType: OrderShippingType, weightInGrams: number, extraData: object) {
    return this.$fetch(
      `${this.resource}/getShippingCost`,
      { params: { shippingTypeId: shippingType, weightInGrams, ...extraData } }
    )
  }

  public getShippingIntervals (params: object) {
    return this.$fetch(`${this.resource}/getShippingIntervals`, { headers: getAuthHeaders(this.authStore.token), params })
  }

  public exportBarcode (orderId: string) {
    return this.$fetch(
      `${this.resource}/exportBarcode`,
      {
        headers: getAuthHeaders(this.authStore.token),
        params: { orderId },
        responseType: 'arrayBuffer'
      }
    )
  }

  public exportData (exportName: string, authScope: AuthScope, data: object) {
    return this.$fetch(
      `${this.resource}/exportData`,
      {
        method: 'POST',
        body: JSON.stringify({ exportName, ...data }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, authScope) },
        responseType: 'arrayBuffer'
      }
    )
  }

  public getPaymentUrl (orderId: string) {
    return this.$fetch(
      `${this.resource}/getPaymentUrl`,
      { headers: getAuthHeaders(this.authStore.token), params: { orderId } }
    )
  }

  public updateProductStock (productId: string, deltaStock: number) {
    return this.$fetch(
      `${this.resource}/updateProductStock`,
      {
        method: 'POST',
        body: JSON.stringify({ deltaStock, productId }),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }
}

import type { $Fetch } from 'nitropack'

import Actions from '~/api/repositories/actions'
import AddressSuggestions from '~/api/repositories/address-suggestions'
import Ambassadors from '~/api/repositories/ambassadors'
import Articles from '~/api/repositories/articles'
import Auth from '~/api/repositories/auth'
import BackInStockNotifications from '~/api/repositories/back-in-stock-notifications'
import BankSuggestions from '~/api/repositories/bank-suggestions'
import Banners from '~/api/repositories/banners'
import Brands from '~/api/repositories/brands'
import CartProducts from '~/api/repositories/cart-products'
import Categories from '~/api/repositories/categories'
import CategoryFaqs from '~/api/repositories/category-faqs'
import Certificates from '~/api/repositories/certificates'
import Collections from '~/api/repositories/collections'
import Consultations from '~/api/repositories/consultations'
import DealerApplications from '~/api/repositories/dealer-applications'
import Export from '~/api/repositories/export'
import Guests from '~/api/repositories/guests'
import Lists from '~/api/repositories/lists'
import Messages from '~/api/repositories/messages'
import Mindbox from '~/api/repositories/mindbox'
import OrderTags from '~/api/repositories/order-tags'
import Orders from '~/api/repositories/orders'
import OrganizationSuggestions from '~/api/repositories/organization-suggestions'
import ProductQuestions from '~/api/repositories/product-questions'
import Products from '~/api/repositories/products'
import PromoCodeApplications from '~/api/repositories/promo-code-applications'
import PromoCodes from '~/api/repositories/promo-codes'
import QualityCertificates from '~/api/repositories/quality-certificates'
import RecommendationDocuments from '~/api/repositories/recommendation-documents'
import Recommendations from '~/api/repositories/recommendations'
import Reviews from '~/api/repositories/reviews'
import Scripts from '~/api/repositories/scripts'
import Services from '~/api/repositories/services'
import SocialNetworks from '~/api/repositories/social-networks'
import SpecialOffers from '~/api/repositories/special-offers'
import Specialists from '~/api/repositories/specialists'
import Specialities from '~/api/repositories/specialities'
import Specializations from '~/api/repositories/specializations'
import Subscribers from '~/api/repositories/subscribers'
import SupplierApplications from '~/api/repositories/supplier-applications'
import SupplierFaqs from '~/api/repositories/supplier-faqs'
import Suppliers from '~/api/repositories/suppliers'
import Tags from '~/api/repositories/tags'
import TestCodes from '~/api/repositories/test-codes'
import TestResults from '~/api/repositories/test-results'
import Tests from '~/api/repositories/tests'
import Users from '~/api/repositories/users'
import Warehouses from '~/api/repositories/warehouses'
import WishListProducts from '~/api/repositories/wish-list-products'
import Withdrawals from '~/api/repositories/withdrawals'
import type { useAuthStore } from '~/store/auth'

export default class Api {
  public actions: Actions
  public addressSuggestions: AddressSuggestions
  public ambassadors: Ambassadors
  public articles: Articles
  public auth: Auth
  public backInStockNotifications: BackInStockNotifications
  public bankSuggestions: BankSuggestions
  public banners: Banners
  public brands: Brands
  public cartProducts: CartProducts
  public categories: Categories
  public categoryFaqs: CategoryFaqs
  public certificates: Certificates
  public collections: Collections
  public consultations: Consultations
  public dealerApplications: DealerApplications
  public export: Export
  public guests: Guests
  public lists: Lists
  public messages: Messages
  public mindbox: Mindbox
  public orders: Orders
  public orderTags: OrderTags
  public organizationSuggestions: OrganizationSuggestions
  public productQuestions: ProductQuestions
  public products: Products
  public promoCodeApplications: PromoCodeApplications
  public promoCodes: PromoCodes
  public qualityCertificates: QualityCertificates
  public recommendationDocuments: RecommendationDocuments
  public recommendations: Recommendations
  public reviews: Reviews
  public scripts: Scripts
  public services: Services
  public socialNetworks: SocialNetworks
  public specialists: Specialists
  public specialities: Specialities
  public specializations: Specializations
  public specialOffers: SpecialOffers
  public subscribers: Subscribers
  public suppliers: Suppliers
  public supplierApplications: SupplierApplications
  public supplierFaqs: SupplierFaqs
  public tags: Tags
  public testCodes: TestCodes
  public testResults: TestResults
  public tests: Tests
  public users: Users
  public warehouses: Warehouses
  public wishListProducts: WishListProducts
  public withdrawals: Withdrawals

  constructor (fetcher: $Fetch, authStore: ReturnType<typeof useAuthStore>) {
    this.actions = new Actions(fetcher, authStore)
    this.addressSuggestions = new AddressSuggestions(fetcher, authStore)
    this.ambassadors = new Ambassadors(fetcher, authStore)
    this.articles = new Articles(fetcher, authStore)
    this.auth = new Auth(fetcher, authStore)
    this.backInStockNotifications = new BackInStockNotifications(fetcher, authStore)
    this.bankSuggestions = new BankSuggestions(fetcher, authStore)
    this.banners = new Banners(fetcher, authStore)
    this.brands = new Brands(fetcher, authStore)
    this.cartProducts = new CartProducts(fetcher, authStore)
    this.categories = new Categories(fetcher, authStore)
    this.categoryFaqs = new CategoryFaqs(fetcher, authStore)
    this.certificates = new Certificates(fetcher, authStore)
    this.collections = new Collections(fetcher, authStore)
    this.consultations = new Consultations(fetcher, authStore)
    this.dealerApplications = new DealerApplications(fetcher, authStore)
    this.export = new Export(fetcher, authStore)
    this.guests = new Guests(fetcher, authStore)
    this.lists = new Lists(fetcher, authStore)
    this.messages = new Messages(fetcher, authStore)
    this.mindbox = new Mindbox(fetcher, authStore)
    this.orders = new Orders(fetcher, authStore)
    this.orderTags = new OrderTags(fetcher, authStore)
    this.organizationSuggestions = new OrganizationSuggestions(fetcher, authStore)
    this.productQuestions = new ProductQuestions(fetcher, authStore)
    this.products = new Products(fetcher, authStore)
    this.promoCodeApplications = new PromoCodeApplications(fetcher, authStore)
    this.promoCodes = new PromoCodes(fetcher, authStore)
    this.qualityCertificates = new QualityCertificates(fetcher, authStore)
    this.recommendationDocuments = new RecommendationDocuments(fetcher, authStore)
    this.recommendations = new Recommendations(fetcher, authStore)
    this.reviews = new Reviews(fetcher, authStore)
    this.scripts = new Scripts(fetcher, authStore)
    this.services = new Services(fetcher, authStore)
    this.socialNetworks = new SocialNetworks(fetcher, authStore)
    this.subscribers = new Subscribers(fetcher, authStore)
    this.specialists = new Specialists(fetcher, authStore)
    this.specialities = new Specialities(fetcher, authStore)
    this.specializations = new Specializations(fetcher, authStore)
    this.specialOffers = new SpecialOffers(fetcher, authStore)
    this.suppliers = new Suppliers(fetcher, authStore)
    this.supplierApplications = new SupplierApplications(fetcher, authStore)
    this.supplierFaqs = new SupplierFaqs(fetcher, authStore)
    this.tags = new Tags(fetcher, authStore)
    this.testCodes = new TestCodes(fetcher, authStore)
    this.testResults = new TestResults(fetcher, authStore)
    this.tests = new Tests(fetcher, authStore)
    this.users = new Users(fetcher, authStore)
    this.warehouses = new Warehouses(fetcher, authStore)
    this.wishListProducts = new WishListProducts(fetcher, authStore)
    this.withdrawals = new Withdrawals(fetcher, authStore)
  }
}

import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import type AuthScope from '~/enums/auth-scope'

export default class ProductQuestions extends HttpFactory {
  private readonly resource = 'v1/productQuestions'

  public answerProductQuestion (productQuestionId: string, data: object, authScope: AuthScope) {
    return this.$fetch(
      `${this.resource}/${productQuestionId}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, authScope) }
      }
    )
  }

  public createProductQuestion (productId: string, data: object, authScope: AuthScope) {
    return this.$fetch(
      `${this.resource}/${productId}`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, authScope) }
      }
    )
  }

  public getProductQuestion (productQuestionId: string, authScope: AuthScope) {
    return this.$fetch(
      `${this.resource}/${productQuestionId}`,
      { headers: getAuthHeaders(this.authStore.token, authScope) }
    )
  }

  public getProductQuestions (params: object, authScope: AuthScope) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token, authScope), params })
  }

  public updateProductQuestion (productQuestionId: string, data: object, authScope: AuthScope) {
    return this.$fetch(
      `${this.resource}/${productQuestionId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, authScope) }
      }
    )
  }
}

import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import type AuthScope from '~/enums/auth-scope'
import type { GetOrderTagsResponse } from '~/types/orders'

export default class OrderTags extends HttpFactory {
  private readonly resource = 'v1/orderTags'

  public getOrderTags (authScope: AuthScope): Promise<GetOrderTagsResponse> {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token, authScope) })
  }
}

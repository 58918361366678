import HttpFactory from '~/api/http-factory'
import type { GetMindboxRecommendationsResponse } from '~/types/mindbox'

export default class Mindbox extends HttpFactory {
  public getMindboxRecommendations (operation: string, data: object): Promise<GetMindboxRecommendationsResponse> {
    return this.$fetch(
      `https://api.mindbox.ru/v3/operations/sync?endpointId=iTAB.Website&operation=${operation}`,
      { method: 'POST', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' } }
    )
  }
}
